import {AutocompleteInput, Create, ReferenceInput, SimpleForm, TextInput} from "react-admin";
import useCreateWithValidation from "../hooks/useCreateWithValidation";

function parse(id: number) {
  return id ?  { id } : null;
}

function format(category: any) {
  return category ? category.id : null;
}

export default function CategoryCreate(props: any) {
  const onSubmit = useCreateWithValidation();

  return (
    <Create {...props}>
        <SimpleForm onSubmit={onSubmit}>
          <TextInput name="name" source="name" />
          <ReferenceInput source="parent" reference="category">
            <AutocompleteInput label="Parent category" format={format} parse={parse} />
          </ReferenceInput>
        </SimpleForm>
    </Create>
  );
}
