import {Datagrid, List, TextField, EditButton, BooleanField} from "react-admin";

export default function PageList() {
  return <List>
    <Datagrid>
      <TextField source="slug" />
      <TextField source="title" />
      <BooleanField source="active" />
      <EditButton />
    </Datagrid>
  </List>
}
