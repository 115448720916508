import {
  AutocompleteInput,
  Edit,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import useUpdateWithValidation from "../hooks/useUpdateWithValidation";

function parse(id: number | string) {
  return id ? { id } : null;
}

function format(category: any) {
  return category ? category.id : null;
}

export default function CategoryEdit(props: any) {
  const update = useUpdateWithValidation();

  return (
    <Edit {...props} redirect="show">
      <SimpleForm onSubmit={update}>
        <TextInput name="name" source="name" />
        <ReferenceInput source="parent" reference="category">
          <AutocompleteInput label="Parent category" format={format} parse={parse} defaultValue={null} />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}
