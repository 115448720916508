import {
  Edit,
  SimpleForm,
  TextInput,
} from "react-admin";
import useUpdateWithValidation from "../hooks/useUpdateWithValidation";

export default function UserEdit(props: any) {
  const update = useUpdateWithValidation();

  return (
    <Edit {...props} redirect="show">
      <SimpleForm onSubmit={update}>
        <TextInput name="email" source="email" />
      </SimpleForm>
    </Edit>
  );
}
