import {useMemo} from "react";
import {Admin, CustomRoutes, EditGuesser, ListGuesser, Resource} from "react-admin";
import {Route} from "react-router";
import {BrowserRouter} from "react-router-dom";
import UserCreate from "./components/UserCreate";
import UserEdit from "./components/UserEdit";
import UserList from "./components/UserList";
import ProductCreate from "./components/ProductCreate";
import ProductEdit from "./components/ProductEdit";
import ProductList from "./components/ProductList";
import ResetPassword from "./components/ResetPassword";
import createDataProvider from "./providers/createDataProvider";
import createAuthProvider from "./providers/createAuthProvider";
import CategoryCreate from "./components/CategoryCreate";
import CategoryList from "./components/CategoryList";
import CategoryEdit from "./components/CategoryEdit";
import PageCreate from "./components/PageCreate";
import PageList from "./components/PageList";
import PageEdit from "./components/PageEdit";

const App = () => {
  const dataProvider = useMemo(createDataProvider, []);
  const authProvider = useMemo(createAuthProvider, []);

  return (
    <BrowserRouter>
     <Admin dataProvider={dataProvider} authProvider={authProvider}>
        <CustomRoutes>
          <Route path="/reset-password/:tokenId" element={<ResetPassword />} />
        </CustomRoutes>
        <Resource name="user" list={UserList} edit={UserEdit} create={UserCreate} />
        <Resource name="product" list={ProductList} edit={ProductEdit} create={ProductCreate}/>
        <Resource name="category" list={CategoryList} edit={CategoryEdit} create={CategoryCreate}/>
        <Resource name="page" list={PageList} edit={PageEdit} create={PageCreate} />
      </Admin>
    </BrowserRouter>
  )
};

export default App;
