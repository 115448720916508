import jsonServerProvider from "ra-data-json-server";
import {DataProvider, fetchUtils} from "react-admin";
import {getApiUrl} from "../config";

interface FileForm {
  rawFile: File;
  src: string;
  title: string;
}

function isFileForm(x: any): x is FileForm {
  return x && x.rawFile instanceof File && typeof x.src === "string" && typeof x.title === "string";
}

async function prepare<T>(item: T): Promise<T> {
  if (Array.isArray(item)) {
    const result = [];

    for (const value of item) {
      result.push(await prepare(value));
    }

    return result as unknown as T;
  }

  if (isFileForm(item)) {
    return {
      name: item.title,
      base64: await convertFileToBase64(item.rawFile)
    } as unknown as  T;
  }

  if (item && typeof item === "object") {
    const result: any = {};

    for (let [key, value] of Object.entries(item)) {
      value = await prepare(value);
      result[key] = value;
    }

    return result;
  }

  return item;
}

const convertFileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });

export default function createDataProvider(): DataProvider {
  const url = getApiUrl();

  const dataProvider: DataProvider = jsonServerProvider(url, async (url, options) => {
    options = options || {};
    options.credentials = "include";
    options.mode = "cors";

    const response = await fetchUtils.fetchJson(url, options);

    if (response.headers.has("x-total-count")) {
      const headers = new Headers(response.headers);
      headers.set("X-Total-Count", headers.get("x-total-count") as string);
      response.headers = headers;
    }

    return response;
  });

  return {
    ...dataProvider,
    async create(resource, params) {
      const data = await prepare(params.data);

      return dataProvider.create(resource, { ...params, data });
    },
    async update(resource, params) {
      const data = await prepare(params.data);

      return dataProvider.update(resource, { ...params, data });
    }
  }
}
