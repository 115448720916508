import {useCallback} from "react";
import {Button, useNotify, useRecordContext} from "react-admin";
import useFetch from "../hooks/useFetch";

export default function UserListResetPassword() {
  const { email } = useRecordContext();
  const notify = useNotify();
  const fetch = useFetch();

  const onClick = useCallback(async () => {
    await fetch(`/auth/password/reset/${email}`);

    notify(`Reset link was sent to ${email}`);
  }, [email]);

  return <Button onClick={onClick}><>Reset password</></Button>
}
