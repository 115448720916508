import {BooleanInput, Create, SimpleForm, TextInput} from "react-admin";
import { RichTextInput } from 'ra-input-rich-text';
import useCreateWithValidation from "../hooks/useCreateWithValidation";

export default function PageCreate(props: any) {
  const onSubmit = useCreateWithValidation();

  return (
    <Create {...props}>
        <SimpleForm onSubmit={onSubmit}>
          <BooleanInput name="active" source="active" label="Active" options={{}} />
          <TextInput name="slug" source="slug" />
          <TextInput name="title" source="title" />
          <RichTextInput name="content" source="content" />
        </SimpleForm>
    </Create>
  );
}
