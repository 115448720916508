import {useCreate, useRedirect, useResourceContext} from "react-admin";
import {useCallback} from "react";

export default function useCreateWithValidation() {
  const redirect = useRedirect();
  const resource = useResourceContext();
  const [create] = useCreate();

  return useCallback(
    async (values: any) => {
      try {
        const record = await create(resource, { data: values }, { returnPromise: true });
        redirect("edit", resource, record.id);
      } catch (error) {
        if ((error as any).body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return (error as any).body.errors;
        }
      }
    },
    [create, resource, redirect]
  );
}
