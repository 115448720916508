import {AuthProvider, fetchUtils} from "react-admin";
import {getApiUrl} from "../config";

export default function createAuthProvider(): AuthProvider {
  return {
    login: async ({ username, password }) => {
      const url = `${getApiUrl()}/auth/login`;

      await fetchUtils.fetchJson(url, {
        method: "POST",
        body: JSON.stringify({
          username,
          password
        }),
        credentials: "include",
      });

      localStorage.setItem("loggedIn", "1");
    },
    checkError: async error => {},
    checkAuth: () => localStorage.getItem("loggedIn") !== null ? Promise.resolve() : Promise.reject(),
    logout: async () => {
      localStorage.removeItem("loggedIn");
    },
    getPermissions: () => Promise.resolve([]),
  };
}
