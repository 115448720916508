import {Create, FileInput, ImageField, SimpleForm, TextInput} from "react-admin";
import {RichTextInput} from "ra-input-rich-text";
import useCreateWithValidation from "../hooks/useCreateWithValidation";

export default function ProductCreate(props: any) {
  const onSubmit = useCreateWithValidation();

  return (
    <Create {...props}>
        <SimpleForm onSubmit={onSubmit}>
          <TextInput name="title" source="title" required />
          <RichTextInput name="description" source="description" />
          <FileInput
            source="photos"
            name="photos"
            label="Photos"
            accept="image/png, image/jpeg"
            multiple
            placeholder={<p>Drop your file here</p>}>
            <ImageField source="url" title="name" />
          </FileInput>
        </SimpleForm>
    </Create>
  );
}
