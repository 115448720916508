import {BooleanInput, Edit, SimpleForm, TextInput} from "react-admin";
import { RichTextInput } from 'ra-input-rich-text';
import useUpdateWithValidation from "../hooks/useUpdateWithValidation";

export default function PageEdit(props: any) {
  const update = useUpdateWithValidation();

  return (
    <Edit {...props}>
        <SimpleForm onSubmit={update}>
          <BooleanInput name="active" source="active" label="Active" options={{}} />
          <TextInput name="slug" source="slug" />
          <TextInput name="title" source="title" />
          <RichTextInput name="content" source="content" />
        </SimpleForm>
    </Edit>
  );
}
