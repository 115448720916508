import {useEditController, useRedirect, useResourceContext, useUpdate} from "react-admin";
import {useCallback} from "react";

export default function useUpdateWithValidation() {
  const { record } = useEditController();
  const redirect = useRedirect();
  const resource = useResourceContext();
  const [update] = useUpdate();

  return useCallback(
    async (values: any) => {
      try {
        await update(resource, { id: record.id, data: values, previousData: record }, { returnPromise: true });
      } catch (error) {
        if ((error as any).body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return (error as any).body.errors;
        }
      }
    },
    [update, resource, redirect]
  );
}
