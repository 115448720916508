import { RichTextInput } from "ra-input-rich-text";
import {
  Edit,
  FileInput,
  ImageField,
  SimpleForm,
  TextInput,
} from "react-admin";
import useUpdateWithValidation from "../hooks/useUpdateWithValidation";

export default function ProductEdit(props: any) {
  const update = useUpdateWithValidation();

  return (
    <Edit {...props} redirect="show">
      <SimpleForm onSubmit={update}>
        <TextInput name="title" source="title" required />
        <RichTextInput name="description" source="description" />
        <FileInput
          source="photos"
          name="photos"
          label="Photos"
          accept="image/png, image/jpeg"
          multiple
          placeholder={<p>Drop your file here</p>}>
            <ImageField source="url" title="name" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
}
