import {Datagrid, List, TextField, EditButton, ReferenceArrayField, ReferenceField, SingleFieldList, ChipField} from "react-admin";
import {ReactNode} from "react";

function ReferenceManyField(props: { reference: string, label: string, target: string, children: ReactNode }) {
  return null;
}

export default function CategoryList() {
  return <List>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceManyField label="Parent" reference="category" target="parent">
        <TextField source="name" />
      </ReferenceManyField>
      <EditButton />
    </Datagrid>
  </List>
}
