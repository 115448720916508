import {Form, PasswordInput, SaveButton, fetchUtils, useNotify} from "react-admin";
import { useParams } from "react-router-dom";
import {useCallback, useState} from "react";
import {Stack} from "@mui/material";
import {getApiUrl} from "../config";

type ResetPasswordParams = {
  tokenId: string;
}

function validate(values: any) {
  const errors: any = {};

  if (values.password !== values.passwordRepeat) {
    errors.password = "Passwords must match";
    errors.passwordRepeat = "Passwords must match";
  }

  if (!values.password) {
    errors.password = "Password cannot be empty";
  }

  if (!values.passwordRepeat) {
    errors.passwordRepeat = "Password cannot be empty";
  }

  return errors;
}

export default function ResetPassword() {
  const { tokenId } = useParams<ResetPasswordParams>();
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const notify = useNotify();

  const onSubmit = useCallback(async (values: any) => {
    const apiUrl = getApiUrl();
    try {
      await fetchUtils.fetchJson(`${apiUrl}/auth/password/reset`, {
        method: "POST",
        body: JSON.stringify({
          token: tokenId,
          password
        })
      });
      notify("Password was changed successfully", {
        type: "success"
      });
    } catch (e) {
      notify("Password could not be set", {
        type: "error"
      });
    }
  }, [tokenId, password]);

  return <Form validate={validate} onSubmit={onSubmit}>
    <Stack>
      <PasswordInput label="Password" source="" name="password" value={password} onChange={e => setPassword(e.target.value)} />
      <PasswordInput label="Repeat password" source="" name="passwordRepeat" value={passwordRepeat} onChange={e => setPasswordRepeat(e.target.value)} />
      <SaveButton>Submit</SaveButton>
    </Stack>
  </Form>;
}
