import {Datagrid, List, EmailField, TextField, EditButton, Button} from "react-admin";
import UserListResetPassword from "./UserListResetPassword";

export default function UserList() {
  return <List>
    <Datagrid>
      <TextField source="id" />
      <EmailField source="email" />
      <UserListResetPassword />
      <EditButton />
    </Datagrid>
  </List>
}
