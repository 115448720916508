import {Create, SimpleForm, TextInput} from "react-admin";
import useCreateWithValidation from "../hooks/useCreateWithValidation";

export default function UserCreate(props: any) {
  const onSubmit = useCreateWithValidation();

  return (
    <Create {...props}>
        <SimpleForm onSubmit={onSubmit}>
          <TextInput name="email" source="email" />
        </SimpleForm>
    </Create>
  );
}
